import React, {useState, useEffect, useRef} from 'react';
import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import {InputMask} from 'primereact/inputmask';
import {InputNumber} from 'primereact/inputnumber';
import {AutoComplete} from 'primereact/autocomplete';
import {Calendar} from 'primereact/calendar';
import {Chips} from 'primereact/chips';
import {Dropdown} from 'primereact/dropdown';
import {MultiSelect} from 'primereact/multiselect';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Messages } from 'primereact/messages';

import { FileUpload } from 'primereact/fileupload';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import {Checkbox} from "primereact/checkbox";
import {RadioButton} from "primereact/radiobutton";
import {InscriptionService} from '../../service/operations/InscriptionsService';
import {ParentService} from '../../service/parametre/ParentService';
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import classNames from "classnames";
 import * as XLSX from "xlsx";
import {MatiereService} from "../../service/parametre/MatiereService";
import {Toolbar} from "primereact/toolbar";
import {EleveService} from "../../service/operations/EleveService";
import {ProgressSpinner} from "primereact/progressspinner";
import {BrancheService} from "../../service/BrancheService";
import {RapportService} from "../../service/rapports/RapportService";


const ImportNoteMatieresEleves = () => {
    let emptyeleve = {
        eleve_mail :'' ,
        eleve_numero_extrait_naiss :0,
        eleveadresse:'',
        elevecellulaire :'',
        elevecode :'',
        elevedate_etabli_extrait_naiss :null,
        elevedate_naissance :null,
        idTenant : 0,
        identifiantEleve : 0,
        elevelieu_etabliss_etrait_naissance :'',
        elevelieu_naissance:'',
        elevenom:'',
        eleveprenom:'' ,
        eleve_matricule_national:''

    };
    let arrayList = [] ;
    let emptyparent = {
        parentid :null ,
        parentcode :'',
        parentnom:'',
        parentprenom :'',
        parent_tel :'',
        parent_tel2 :'',
        parent_profession :'',
        parentemail : '',
        identifiantTypeParent : 0,
        elevelieu_etabliss_etrait_naissance :'',
        identifiantTenant:''

    };
    let emptyColomn= {
        matiere:null,
        periode:null,
        annee:null,
        type:null,
        notesur:'',
        user:'',
        date:'',
        classe:'',
        matricule:'',
        matiereFranId:1,
        noteFran1:'',
        noteFran2:'',
        noteFran3:'',
        noteFran4:'',
        noteFran5:'',
        matiereCompoFrId:2,
        noteCompoFr1:'',
        noteCompoFr2:'' ,
        noteCompoFr3:'' ,
        noteCompoFr4:'' ,
        noteCompoFr5:'' ,
        matiereExpreOralId:3,
         noteExpreOral1 :'',
         noteExpreOral2 :'',
         noteExpreOral3:'' ,
        noteExpreOral4 :'',
         noteExpreOral5:'',
         matiereHgId:6,
         noteHg1 :'',
         noteHg2 :'',
         noteHg3:'',
         noteHg4 :'',
         noteHg5:'' ,
         matiereEdhcId:11,
         noteEdhc1 :'',
         noteEdhc2 :'',
         noteEdhc3:'',
         noteEdhc4 :'',
         noteEdhc5:'',
        matiereOrthoGramId:4,
        noteOrthoGram1:'' ,
        noteOrthoGram2:'' ,
        noteOrthoGram3:'' ,
        noteOrthoGram4:'',
        noteOrthoGram5:'' ,

        matierephilosoId:26,
        notephiloso1:'',
        notephiloso2:'' ,
        notephiloso3:'' ,
        notephiloso4:'',
        notephiloso5:'',

        matiereAngId:5,
        noteAng1:'',
        noteAng2:'' ,
        noteAng3:'' ,
        noteAng4:'' ,
        noteAng5:'',

        matiereMathId:7,
        noteMath1:'',
        noteMath2:'' ,
        noteMath3:'',
        noteMath4:'' ,
        noteMath5:'' ,

        matierePhysiqId:8,
        notePhysiq1:'',
        notePhysiq2:'' ,
        notePhysiq3:'' ,
        notePhysiq4:'' ,
        notePhysiq5:'' ,

        matiereSVTId:9,
        noteSVT1:'',
        noteSVT2:'',
        noteSVT3:'',
        noteSVT4:'' ,
        noteSVT5:'' ,

        matiereEspId:21,
        noteEsp1:'',
        noteEsp2:'',
        noteEsp3:'' ,
        noteEsp4:'' ,
        noteEsp5:'' ,

        matiereAllId:25,
        noteAll1:'',
        noteAll2:'',
        noteAll3:'' ,
        noteAll4:'' ,
        noteAll5:'' ,

        matiereArplatId:36,
        noteArplat1:'',
        noteArplat2:'',
        noteArplat3:'',
        noteArplat4:'',
        noteArplat5:'',

        matiereTicId:13,
        noteTic1:'',
        noteTic2:'' ,
        noteTic3:'' ,
        noteTic4:'' ,
        noteTic5:'' ,

        matiereConduiteId:12 ,
        noteConduite1:'' ,
        noteConduite2:'' ,
        noteConduite3:'' ,
        noteConduite4:'' ,
        noteConduite5:'' ,

        matiereEpsId:10 ,
        noteEps1:'' ,
        noteEps2:'' ,
        noteEps3:'' ,
        noteEps4:'' ,
        noteEps5:'' ,


      //  date_convertJson: JSON.parse('37307')
    }

    let emptyColomn2= {
        N:'',
        id_eleve:'',
        matricule:'',
        nom:'',
        prenoms:'',
        statut:'',
        sexe:'',
        red:'',
        lv2:'',
        datenaissance:'',
        branche:'',
        lieun:'',
        nationalite:'',
        adresse:'',
        mobile:'',
        mobile2:'',
        pere:'',
        mere:'',
        tuteur:'',
        redoublant:'',
        regime:'',
        decision_ant:'',
        extrait_numero:'',
        identifiant_classe:null,
        identifiantBranche:'',
        libelleBranche:'',
        Niveau:'',
        extrait_date:'',
        extrait_lieu:'',
        decision_aff:''

        //  date_convertJson: JSON.parse('37307')
    }


    let emptyIdentification = {
        inscriptionsid :null ,
        inscriptionscode :'',
      inscriptions_ecole_origine:'',
       inscriptions_classe_precedente :'',
       inscriptions_derniereclasse_religieuse :'',
        inscriptions_classe_actuelle :'',
         inscriptions_status :'',
      inscriptionsdate_creation : Date.now(),
      identifiantEleve : 0,
      identifiantEcole :0,
        identifiantAnnee_scolaire:0,
       libellehandicap:[]

    };
    let emptyAnneeScoliare= {
        annee_scolaireid: 1 ,
        annee_scolaire_libelle: '' ,
        annee_scolaire_code: '',
        annee_scolaire_visible:''
    } ;
    let emptybranche= {
        id: null ,
        libelle: ''
    } ;
    let empty = {
        id :'',
        code :null ,
        libelle :null
    };
    const [annee, setannee] = useState(empty);
    const [columns, setColumns] = useState(null);
    const [column, setColumn] = useState(emptyColomn);
    const [fileName, setFileName] = useState(null);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [annees, setannees] = useState(null);
    const [periode, setPeriode] = useState(empty);
    const [periodes, setPeriodes] = useState(null);
    const [classe, setClasse] = useState(empty);
    const [classes, setClasses] = useState(null);

    const [createProductsDialog , setCreateProductsDialog] = useState(false);
    const [updateProductsDialog , setUpdateProductsDialog] = useState(false);

    const [retourApi, setretourApi]  = useState(null);
    const [showMessage, setShowMessage] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [importedData, setImportedData] = useState([]);
    const [selectedImportedData, setSelectedImportedData] = useState([]);
    const [importedCols, setImportedCols] = useState([{ field: '', header: 'Header' }]);
    const dt = useRef(null);
    const [countries, setCountries] = useState([]);
    const [handicaps, setHandicaps] = useState([]);
    const [anneeScolaire, setanneeScolaire]  = useState(emptyAnneeScoliare);
   const [anneeEncours, setAnneeEncours] = useState(sessionStorage.getItem('AnneEncours'));
   // const [anneeEncours, setAnneeEncours] = useState(51);
    const [inscriptions, setinscriptions] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [typeOperation1, settypeOperation1]  = useState("INSCRIPTION");
    const [handicapDialog, sethandicapDialog] = useState(false);
    const [modif, setModif]  = useState(null);
    const [inscription, setinscription]  = useState(emptyIdentification);
    const [eleve, seteleve]  = useState(emptyeleve);
    const [parent, setparent]  = useState(emptyparent);
    const [selectedTypeParent, setSelectedTypeParent] = useState(null);
    const [isNotSpinning, setIsNotSpinning] = useState('hidden');
    const [isNotSpinning1, setIsNotSpinning1] = useState('hidden');
    const [branches, setBranches] = useState(null);
    const [branche, setBranche] = useState(emptybranche);
    const [contentMess, setContentMess] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [submittedHandicap, setSubmittedHandicap] = useState(false);

    const [filteredinscriptions, setFilteredinscriptions] = useState(null);
    const [tenant, settenant]  = useState(sessionStorage.getItem('EcoleID'));
    const [checkboxValue, setCheckboxValue] = useState([]);
    const [radioValue, setRadioValue] = useState('non');
    const msgs = useRef(null);
    const msgs2 = useRef(null);
    const msgs3 = useRef(null);
    const toast = useRef(null);

    const [newData, setNewData] = useState(null);

    const TypeParents = [
        {type_parentlibelle: 'PERE', type_parentcode: 'PERE'},
        {type_parentlibelle: 'MERE', type_parentcode: 'MERE'},
        {type_parentlibelle: 'TUTEUR', type_parentcode: 'TUTEUR'},
        {type_parentlibelle: 'PERSONNE EN CHARGE', type_parentcode: 'PERSONNE EN CHARGE'}

    ];

    const onTypeParentChange = (e) => {
        setSelectedTypeParent(e.value);
    }




    useEffect(() => {
        const  rapp= new RapportService() ;
        rapp.getListClasseParEcole(tenant).then(data => setClasses(data));
        rapp.listPeriode().then(data => setPeriodes(data));
        rapp.listAnnee(tenant).then(data => setannees(data));
        const brancheService = new BrancheService();
        brancheService.getByNiveauEnseigment(sessionStorage.getItem('EcoleID')).then(resp => setBranches(resp));
        const eleveService = new EleveService();
        eleveService.AnneeScolaire(tenant).then(data => {
            console.log(data)
            setanneeScolaire(data)
        });




    }, []);
    const onClasseChange = (e) => {
        setClasse(e.value);
    }
    const onPeriodeChange = (e) => {
        setPeriode(e.value);
    }
    const onAnneeChange = (e) => {
        setannee(e.value);
    }
 const  onRadioChange = (e) => {
        setRadioValue(e.value);
     setHandicaps([]) ;
     setSubmittedHandicap(false) ;
     // setSubmitted(false);
     sethandicapDialog(true) ;
    }

    useEffect(()=>{
        if(modif===1){
            setShowMessage(true);
        }
    },[modif])


    const createSelectedProducts = () => {




        setIsNotSpinning('');
        let _products = columns.filter(val => !selectedProducts.includes(val));
        setColumns(_products);
        console.log("Tableau+++ "+selectedProducts) ;
        const eleveService = new EleveService();
       // anneeEncours
        const newData1 = selectedProducts.map((item) => {

            if (item.matricule === 'NULL') {
                item.matricule = "";
            }
            if (item.noteFran1 === 'NULL') {
                item.noteFran1 = "";
            }
            if (item.noteFran2 === 'NULL') {
                item.noteFran2 ="";
            }
            if (item.noteFran3 === 'NULL') {
                item.noteFran3 ="";
            }
            if (item.noteFran4 === 'NULL') {
                item.noteFran4 ="";
            }
            if (item.noteFran5 === 'NULL') {
                item.noteFran5 ="";
            }


            if (item.noteCompoFr1 === 'NULL') {
                item.noteCompoFr1 ="";
            }
            if (item.noteCompoFr2 === 'NULL') {
                item.noteCompoFr2 ="";
            }
            if (item.noteCompoFr3 === 'NULL') {
                item.noteCompoFr3 ="";
            }
            if (item.noteCompoFr4 === 'NULL') {
                item.noteCompoFr4 ="";
            }
            if (item.noteCompoFr5 === 'NULL') {
                item.noteCompoFr5 ="";
            }
            if (item.noteOrthoGram1 === 'NULL') {
                item.noteOrthoGram1 ="";
            }
            if (item.noteOrthoGram2 === 'NULL') {
                item.noteOrthoGram2 ="";
            }
            if (item.noteOrthoGram3 === 'NULL') {
                item.noteOrthoGram3 ="";
            }
            if (item.noteOrthoGram4 === 'NULL') {
                item.noteOrthoGram4 ="";
            }
            if (item.noteOrthoGram5 === 'NULL') {
                item.noteOrthoGram5 ="";
            }

            if (item.notephiloso1 === 'NULL') {
                item.notephiloso1 ="";
            }
            if (item.notephiloso2 === 'NULL') {
                item.notephiloso2 ="";
            }
            if (item.notephiloso3 === 'NULL') {
                item.notephiloso3 ="";
            }
            if (item.notephiloso4 === 'NULL') {
                item.notephiloso4 ="";
            }
            if (item.notephiloso5 === 'NULL') {
                item.notephiloso5 ="";
            }
            if (item.noteAng1 === 'NULL') {
                item.noteAng1 ="";
            }

            if (item.noteAng2 === 'NULL') {
                item.noteAng2 ="";
            }

            if (item.noteAng3 === 'NULL') {
                item.noteAng3 ="";
            }

            if (item.noteAng4 === 'NULL') {
                item.noteAng4 ="";
            }

            if (item.noteAng5 === 'NULL') {
                item.noteAng5 ="";
            }

            if (item.noteMath1 === 'NULL') {
                item.noteMath1 ="";
            }
            if (item.noteMath2 === 'NULL') {
                item.noteMath2 ="";
            }

            if (item.noteMath4 === '') {
                item.noteMath4 ="";
            }
            if (item.noteMath5 === '') {
                item.noteMath5 ="";
            }
            if (item.notePhysiq1 === 'NULL') {
                item.notePhysiq1 ="";
            }
            if (item.notePhysiq2 === 'NULL') {
                item.notePhysiq2 ="";
            }
            if (item.notePhysiq3 === 'NULL') {
                item.notePhysiq3 ="";
            }
            if (item.notePhysiq4 === 'NULL') {
                item.notePhysiq4 ="";
            }
            if (item.notePhysiq5 === 'NULL') {
                item.notePhysiq5 ="";
            }
            if (item.noteSVT1 === 'NULL') {
                item.noteSVT1 ="";
            }
            if (item.noteSVT2 === 'NULL') {
                item.noteSVT2 ="";
            }
            if (item.noteSVT3 === 'NULL') {
                item.noteSVT3 ="";
            }
            if (item.noteSVT4 === 'NULL') {
                item.noteSVT4 ="";
            }
            if (item.noteSVT5 === 'NULL') {
                item.noteSVT5 ="";
            }
            if (item.noteEsp1 === 'NULL') {
                item.noteEsp1 ="";
            }
            if (item.noteEsp2 === 'NULL') {
                item.noteEsp2 ="";
            }
            if (item.noteEsp3 === 'NULL') {
                item.noteEsp3 ="";
            }
            if (item.noteEsp4 === 'NULL') {
                item.noteEsp4 ="";
            }
            if (item.noteEsp5 === 'NULL') {
                item.noteEsp5 ="";
            }
            if (item.noteAll1 === 'NULL') {
                item.noteAll1 ="";
            }
            if (item.noteAll2 === 'NULL') {
                item.noteAll2 ="";
            }
            if (item.noteAll3 === 'NULL') {
                item.noteAll3 ="";
            }
            if (item.noteAll4 === 'NULL') {
                item.noteAll4 ="";
            }
            if (item.noteAll5 === 'NULL') {
                item.noteAll5 ="";
            }
            if (item.noteArplat1 === 'NULL') {
                item.noteArplat1 ="";
            }
            if (item.noteArplat2 === 'NULL') {
                item.noteArplat2 ="";
            }
            if (item.noteArplat3 === 'NULL') {
                item.noteArplat3 ="";
            }
            if (item.noteArplat4 === 'NULL') {
                item.noteArplat4 ="";
            }
            if (item.noteArplat5 === 'NULL') {
                item.noteArplat5 ="";
            }
            if (item.noteAll1 === 'NULL') {
                item.noteAll1 ="";
            }
            if (item.noteAll2 === 'NULL') {
                item.noteAll2 ="";
            }
            if (item.noteAll3 === 'NULL') {
                item.noteAll3 ="";
            }

            if (item.noteAll4 === 'NULL') {
                item.noteAll4 ="";
            }
            if (item.noteAll5 === 'NULL') {
                item.noteAll5 ="";
            }
            if (item.noteArplat1 === 'NULL') {
                item.noteArplat1 ="";
            }
            if (item.noteArplat2 === 'NULL') {
                item.noteArplat2 ="";
            }
            if (item.noteArplat3 === 'NULL') {
                item.noteArplat3 ="";
            }
            if (item.noteArplat4 === 'NULL') {
                item.noteArplat4 ="";
            }
            if (item.noteArplat5 === 'NULL') {
                item.noteArplat5 ="";
            }
            if (item.noteTic1 === 'NULL') {
                item.noteTic1 ="";
            }
            if (item.noteTic2 === 'NULL') {
                item.noteTic2 ="";
            }
            if (item.noteTic3 === 'NULL') {
                item.noteTic3 ="";
            }
            if (item.noteTic4 === 'NULL') {
                item.noteTic4 ="";
            }
            if (item.noteTic5 === 'NULL') {
                item.noteTic5 ="";
            }
            if (item.noteConduite1 === 'NULL') {
                item.noteConduite1 ="";
            }
            if (item.noteConduite2 === 'NULL') {
                item.noteConduite2 ="";
            }
            if (item.noteConduite3 === 'NULL') {
                item.noteConduite3 ="";
            }
            if (item.noteConduite4 === 'NULL') {
                item.noteConduite4 ="";
            }
            if (item.noteConduite5 === 'NULL') {
                item.noteConduite5 ="";
            }
            if (item.noteEps1 === 'NULL') {
                item.noteEps1 ="";
            }
            if (item.noteEps2 === 'NULL') {
                item.noteEps2 ="";
            }
            if (item.noteEps3 === 'NULL') {
                item.noteEps3 ="";
            }
            if (item.noteEps4 === 'NULL') {
                item.noteEps4 ="";
            }
            if (item.noteEps5 === 'NULL') {
                item.noteEps5 ="";
            }

            if (item.noteExpreOral1 === 'NULL') {
                item.noteExpreOral1 ="";
            }
            if (item.noteExpreOral2 === 'NULL') {
                item.noteExpreOral2 ="";
            }
            if (item.noteExpreOral3 === 'NULL') {
                item.noteExpreOral3 ="";
            }
            if (item.noteExpreOral4 === 'NULL') {
                item.noteExpreOral4 ="";
            }
            if (item.noteExpreOral5 === 'NULL') {
                item.noteExpreOral5 ="";
            }
            if (item.noteHg1 === 'NULL') {
                item.noteHg1 ="";
            }
            if (item.noteHg2 === 'NULL') {
                item.noteHg2 ="";
            }
            if (item.noteHg3 === 'NULL') {
                item.noteHg3 ="";
            }
            if (item.noteHg4 === 'NULL') {
                item.noteHg4 ="";
            }
            if (item.noteHg5 === 'NULL') {
                item.noteHg5 ="";
            }
            if (item.noteEdhc1 === 'NULL') {
                item.noteEdhc1 ="";
            }
            if (item.noteEdhc2 === 'NULL') {
                item.noteEdhc2 ="";
            }
            if (item.noteEdhc3 === 'NULL') {
                item.noteEdhc3 ="";
            }
            if (item.noteEdhc4 === 'NULL') {
                item.noteEdhc4 ="";
            }
            if (item.noteEdhc5 === 'NULL') {
                item.noteEdhc5 ="";
            }



            item.annee=annee.id;
            item.periode=periode.id;
            item.classe=classe.id;
            item.matiereMathId=7;
            item.matiereFranId=1;
            item.matiereCompoFrId=2;
            item.matiereExpreOralId=3;
            item.matiereOrthoGramId=4;
            item.matiereHgId=6;
            item.matiereEdhcId=11;
            item.matierephilosoId=26;
            item.matiereAngId=5;
            item.matierePhysiqId=8;
            item.matiereSVTId=9;
            item.matiereEspId=21;
            item.matiereAllId=25;
            item.matiereArplatId=36;
            item.matiereTicId=13;
            item.matiereConduiteId=12;
            item.matiereEpsId=10;

            return item;
        });



        eleveService.importerNoteEleveClasse(newData1).then(data => {
            console.log(data)
            setretourApi(data)
            setCreateProductsDialog(false);
            setSelectedProducts(null);
            setModif(1);
            setIsNotSpinning('hidden');
              }).catch((error) => {
            if (error.response) {
                console.log('erreur response-' + error.response.status);

                setContentMess(error.response.data) ;
                console.log("Content Message "+error.response.data);

              toast.current.show({ severity: 'error', summary: 'Attention', detail: error.response.data, life: 50000 });
                setCreateProductsDialog(false);
                setSelectedProducts(null);
                setIsNotSpinning('hidden');
            } else if (error.request) {
                console.log('erreur request- ' + error.request);
            } else {
                console.log('Error autres', error.message);
            }
        });
    }

    const updateSelectedProducts = () => {
        setIsNotSpinning1('');
        let _products = columns.filter(val => !selectedProducts.includes(val));
        setColumns(_products);
        console.log("Tableau+++ "+selectedProducts) ;
        const eleveService = new EleveService();
        const newData1 = selectedProducts.map((item) => {

            if (item.matricule === 'NULL') {
                item.matricule = "";
            }
            if (item.nom === 'NULL') {
                item.nom = "";
            }
            if (item.prenoms === 'NULL') {
                item.prenoms ="";
            }
            if (item.statut === 'NULL') {
                item.statut ="";
            }
            if (item.nationalite === 'NULL') {
                item.nationalite ="";
            }
            if (item.lv2 === 'NULL') {
                item.lv2 ="";
            }
            if (item.datenaissance === 'NULL'||item.datenaissance === '') {
                item.datenaissance ='01/01/1900';
            }

            if (item.lieun === 'NULL') {
                item.lieun ="";
            }
            if (item.nationalite === 'NULL') {
                item.nationalite ="";
            }
            if (item.adresse === 'NULL') {
                item.adresse ="";
            }
            if (item.mobile === 'NULL') {
                item.mobile ="";
            }
            if (item.mobile2 === 'NULL') {
                item.mobile2 ="";
            }
            if (item.pere === 'NULL') {
                item.pere ="";
            }
            if (item.mere === 'NULL') {
                item.mere ="";
            }
            if (item.tuteur === 'NULL') {
                item.tuteur ="";
            }
            if (item.redoublant === 'NULL') {
                item.redoublant ="";
            }
            if (item.regime === 'NULL') {
                item.regime ="";
            }

            if (item.decision_ant === 'NULL') {
                item.decision_ant ="";
            }
            if (item.extrait_numero === 'NULL') {
                item.extrait_numero ="";
            }
            if (item.extrait_date === 'NULL'||item.extrait_date === '') {
                item.extrait_date ='01/01/1900';
            }
            if (item.extrait_lieu === 'NULL') {
                item.extrait_lieu ="";
            }
            if (item.decision_ant === 'NULL') {
                item.decision_ant ="";
            }
            if (item.decision_aff === 'NULL') {
                item.decision_aff ="";
            }

            if (item.ivoirien === 'NULL'||item.ivoirien === '') {
                item.ivoirien ="OUI";
            }

            if (item.etranger_africain === 'NULL'||item.etranger_africain === '') {
                item.etranger_africain ="NON";
            }

            if (item.etranger_non_africain === 'NULL'||item.etranger_non_africain === '') {
                item.etranger_non_africain ="NON";
            }


            return item;
        });

        eleveService.importerIEtUpdateEleves(tenant,anneeEncours,typeOperation1,branche.id,newData1).then(data => {
            console.log(data)
            setretourApi(data)
            setUpdateProductsDialog(false);
            setSelectedProducts(null);
            setModif(1);
            setIsNotSpinning('hidden');
        }).catch((error) => {
            if (error.response) {
                console.log('erreur response-' + error.response.status);

                setContentMess(error.response.data) ;
                console.log("Content Message "+error.response.data);

                toast.current.show({ severity: 'error', summary: 'Erreur', detail: error.response.data, life: 50000 });
                setUpdateProductsDialog(false);
                setSelectedProducts(null);
                setIsNotSpinning('hidden');
            } else if (error.request) {
                console.log('erreur request- ' + error.request);
            } else {
                console.log('Error autres', error.message);
            }
        });
    }
    const onCheckboxChange = (e) => {
        let selectedValue = [...checkboxValue];
        if (e.checked)
            selectedValue.push(e.value);
        else
            selectedValue.splice(selectedValue.indexOf(e.value), 1);

        setCheckboxValue(selectedValue);
    };
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';

    }
    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;

    }
    const openNew = () =>{
        setCreateProductsDialog(true);
       /* if(periode.id && annee.id && classe.id){
            setCreateProductsDialog(true);
        } else {
 toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Veuillez selectionner tous les champs', life: 3000 });
        }*/

    }

    const update = () =>{
        setUpdateProductsDialog(true);
    }

    const openNewHandicap = () => {
        //setparent(emptyparent);
      setRadioValue('oui')
       setHandicaps([]) ;
        setSubmittedHandicap(false) ;
       // setSubmitted(false);
        sethandicapDialog(true) ;
       // setProductDialog(true);
    }

    const saveParent = () => {
        setSubmitted(true);
        if (parent.parentnom.trim()&& parent.parentprenom.trim()&&parent.parent_tel.trim()) {
            const parentService = new ParentService();
            parentService.creerParent(parent) ;
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Created', life: 3000 });
            setProductDialog(false);

        }
    }

    const saveHandicap = () => {
        setSubmittedHandicap(true);
        setHandicaps(checkboxValue);

    }
    const toCapitalize = (s) => {
        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    const clear = () => {
        setImportedData([]);
        setSelectedImportedData([]);
        setImportedCols([{ field: '', header: 'Header' }]);
    }

    const onImportSelectionChange = (e) => {
        setSelectedImportedData(e.value);
        const detail = e.value.map(d => Object.values(d)[0]).join(', ');
        toast.current.show({ severity: 'info', summary: 'Data Selected', detail, life: 3000 });
    }

    const onSelectionChange = (e) => {
        setSelectedProducts(e.value);
    }
    const hideCreateProductsDialog = () => {
        setCreateProductsDialog(false);
    };

    const createProductsDialogFooter = (
        <>
            <div className="field">
                <ProgressSpinner className={isNotSpinning}  style={{width: '50px', height: '50px'}}  strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
            </div>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideCreateProductsDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={createSelectedProducts} />
        </>
    );

    const hideUpdateProductsDialog = () => {
        setUpdateProductsDialog(false);
    };
    const updateProductsDialogFooter = (
        <>
            <div className="field">
                <ProgressSpinner className={isNotSpinning1}  style={{width: '50px', height: '50px'}}  strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
            </div>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideUpdateProductsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={updateSelectedProducts} />
        </>
    );



    const searchCountry = (event) => {
        // in a real application, make a request to a remote url with the query and
        // return filtered results, for demo we filter at client side
        const filtered = [];
        const query = event.query;
        for (let i = 0; i < countries.length; i++) {
            const country = countries[i];
            if (country.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                filtered.push(country);
            }
        }
        setFilteredinscriptions(filtered);
    };
 const handleFile = async (e)=>{
     const file= e.target.files[0];
     setFileName(file.name) ;
     const data = await file.arrayBuffer();
     const workbook = XLSX.read(data,{type:'binary',cellText:false,cellDates:true});
     const worksheet = workbook.Sheets[workbook.SheetNames[0]];
     const jsonData = XLSX.utils.sheet_to_json(worksheet,{raw:false,dateNF:'dd/MM/yyyy'});
      setColumns(jsonData);
   console.log("jsonData "+JSON.stringify(columns)) ;

     console.log("DateConvert "+JSON.stringify(columns)) ;




 } ;



    const nomBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Nom </span>
                {rowData.nom===''||rowData.nom==='NULL'? "":rowData.nom}
            </>
        );
    }

    const prenomsBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Prénoms </span>
                {rowData.prenoms===''||rowData.prenoms==='NULL'? "":rowData.prenoms}
            </>
        );
    }

    const statutBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">statut </span>
                {rowData.statut===''||rowData.statut==='NULL'? "":rowData.statut}
            </>
        );
    }

    const sexeBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">sexe </span>
                {rowData.sexe===''||rowData.sexe==='NULL'? "":rowData.sexe}
            </>
        );
    }

    const lv2BodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">lv2 </span>
                {rowData.lv2===''||rowData.lv2==='NULL'? "":rowData.lv2}
            </>
        );
    }

    const datenaissanceBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">datenaissance </span>
                {rowData.datenaissance===''||rowData.datenaissance==='NULL'? "":rowData.datenaissance}
            </>
        );
    }

    const brancheBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">branche </span>
                {rowData.branche===''||rowData.branche==='NULL'? "":rowData.branche}
            </>
        );
    }

    const lieunBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">lieu de naissance </span>
                {rowData.lieun===''||rowData.lieun==='NULL'? "":rowData.lieun}
            </>
        );
    }


    const adresseBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">adresse </span>
                {rowData.adresse===''||rowData.adresse==='NULL'? "":rowData.adresse}
            </>
        );
    }

    const mobileBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">mobile </span>
                {rowData.mobile===''||rowData.mobile==='NULL'? "":rowData.mobile}
            </>
        );
    }

    const mobile2BodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">mobile2 </span>
                {rowData.mobile2===''||rowData.mobile2==='NULL'? "":rowData.mobile2}
            </>
        );
    }

    const pereBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">pere </span>
                {rowData.pere===''||rowData.pere==='NULL'? "":rowData.pere}
            </>
        );
    }

    const mereBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">mere </span>
                {rowData.mere===''||rowData.mere==='NULL'? "":rowData.mere}
            </>
        );
    }

    const tuteurBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">tuteur </span>
                {rowData.tuteur===''||rowData.tuteur==='NULL'? "":rowData.tuteur}
            </>
        );
    }

    const redoublantBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">redoublant </span>
                {rowData.redoublant===''||rowData.redoublant==='NULL'? "":rowData.redoublant}
            </>
        );
    }

    const regimeBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">regime </span>
                {rowData.regime===''||rowData.regime==='NULL'? "":rowData.regime}
            </>
        );
    }

    const decision_antTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">decision_ant </span>
                {rowData.decision_ant===''||rowData.decision_ant==='NULL'? "":rowData.decision_ant}
            </>
        );
    }

    const extrait_numeroBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">extrait_numero </span>
                {rowData.extrait_numero===''||rowData.extrait_numero==='NULL'? "":rowData.extrait_numero}
            </>
        );
    }
    const onSelectBrancheChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        // console.log(val);
        let _branche = { ...branche };

        if (name !== null)
            _branche[`${name}`].id = val;
        else
            _branche.id = val;

        setBranche(_branche);
    }
    const extrait_dateBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">extrait_date </span>
                {rowData.extrait_date===''||rowData.extrait_date==='NULL'? "":rowData.extrait_date}
            </>
        );
    }

    const extrait_lieuBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">extrait_lieu </span>
                {rowData.extrait_lieu===''||rowData.extrait_lieu==='NULL'? "":rowData.extrait_lieu}
            </>
        );
    }

    const decision_affBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">decision_aff </span>
                {rowData.decision_aff===''||rowData.decision_aff==='NULL'? "":rowData.decision_aff}
            </>
        );
    }

    const dialogFooter = <div className="flex justify-content-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => setShowMessage(false)} /></div>;

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>

                <div className="my-2">
                    <input type="file" onChange={(e) => handleFile(e)}></input>
                </div>
                <div className="formgrid grid">
                    <div className="field col">
                        <h8>Année *</h8>
                        <Dropdown id="dropdown" options={annees} value={annee} onChange={onAnneeChange} optionLabel="libelle"

                        />

                    </div>
                    <div className="field col">
                        <h8>Période*</h8>
                        <Dropdown id="dropdown" options={periodes} value={periode} onChange={onPeriodeChange} optionLabel="libelle"

                        />

                    </div>
                    <div className="field col">
                        <h8>Classe</h8>
                        <Dropdown id="dropdown" options={classes} value={classe} onChange={onClasseChange} optionLabel="libelle"

                        />
                    </div>
                    <div className="field col">
                        <Button label="Importer" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} disabled={!selectedProducts || !selectedProducts.length}/>
                    </div>
                    </div>


            </React.Fragment>

    )
    }

    const DateTemplate = (rowData) => {
        return (
            <>
            <span className="p-column-title">Date</span>
                {rowData.date_convertJson}
            </>
        );
    }


    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>


                <Button label="Importer" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} disabled={!selectedProducts || !selectedProducts.length}/>


            </React.Fragment>
        )
    }
    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    }

    const hideHandiDialog = () => {
        setSubmittedHandicap(false);

        sethandicapDialog(false)
    }
    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveParent} />
        </>
    );

    const dateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date de Naissance </span>
                {rowData.datenaissance==null?"":rowData.datenaissance}
            </>
        );
    }
    const matriculeBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Matricule</span>
                {rowData.matricule===''||rowData.matricule==='NULL'? "":rowData.id_eleve}
            </>
        );
    }
    const extraiDateBodyTemplate = (rowData) => {

        const extraiD = rowData.extrait_date ;
        const cellStyle ={
            color:extraiD===''||extraiD==='NULL'?'red' :'black'
        }

        return (
            <>
                <span className="p-column-title">Date extrait</span>
                <span style={cellStyle}> {rowData.extrait_date==='NULL'? "":rowData.extrait_date} </span>
            </>
        );
    }


    const handicapDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideHandiDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveHandicap} />
        </>
    );
    return (


            <div className="grid crud-demo">

                <div className="col-12">
                    <Messages ref={msgs2} />
                </div>
                <div className="col-12">
                    <Messages ref={msgs3} />
                </div>
                <div className="col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <Toolbar className="mb-4" left={leftToolbarTemplate}  ></Toolbar>
     {  <DataTable value={columns} responsiveLayout="scroll" ref={dt} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                      dataKey="matricule" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}>
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} exportable={false}></Column>

                <Column field="matricule" header="matricule" ></Column>
                <Column field="noteFran1" header="noteFran1" ></Column>
                <Column field="noteFran2" header="noteFran2" ></Column>
                 <Column field="noteFran3" header="noteFran3" ></Column>
                <Column field="noteFran4" header="noteFran4" ></Column>
               <Column field="noteFran5" header="noteFran5" ></Column>
                  <Column field="noteExpreOral1" header="noteExpreOral1" ></Column>
                 <Column field="noteExpreOral2" header="noteExpreOral2" ></Column>
                 <Column field="noteExpreOral3" header="noteExpreOral3" ></Column>
                 <Column field="noteExpreOral4" header="noteExpreOral4" ></Column>
                 <Column field="noteExpreOral5" header="noteExpreOral5" ></Column>
                <Column field="noteCompoFr1" header="noteCompoFr1" ></Column>
                <Column field="noteCompoFr2" header="noteCompoFr2" ></Column>
               <Column field="noteCompoFr3" header="noteCompoFr3" ></Column>
                 <Column field="noteCompoFr4" header="noteCompoFr4" ></Column>
                 <Column field="noteCompoFr5" header="noteCompoFr5"></Column>
                 <Column field="noteOrthoGram1" header="noteOrthoGram1" ></Column>
                  <Column field="noteOrthoGram2" header="noteOrthoGram2"></Column>
                 <Column field="noteOrthoGram3" header="noteOrthoGram3" ></Column>
                 <Column field="noteOrthoGram4" header="noteOrthoGram4"></Column>
                 <Column field="noteOrthoGram5" header="noteOrthoGram5" ></Column>
                 <Column field="notephiloso1" header="notephiloso1" ></Column>
                 <Column field="notephiloso2" header="notephiloso2" ></Column>
                 <Column field="notephiloso3" header="notephiloso3" ></Column>
                 <Column field="notephiloso4" header="notephiloso4" ></Column>
                 <Column field="notephiloso4" header="notephiloso5" ></Column>
                  <Column field="noteAng1" header="noteAng1" ></Column>
                 <Column field="noteAng2" header="noteAng2" ></Column>
                  <Column field="noteAng3" header="noteAng3"  ></Column>
                   <Column field="noteAng4" header="noteAng4" ></Column>
                     <Column field="noteAng5" header="noteAng5" ></Column>
                     <Column field="noteMath1" header="noteMath1" ></Column>
                   <Column field="noteMath2" header="noteMath2" ></Column>
                 <Column field="noteMath3" header="noteMath3" ></Column>
                 <Column field="noteMath4" header="noteMath4" ></Column>
                 <Column field="noteMath5" header="noteMath5" ></Column>
                 <Column field="notePhysiq1" header="notePhysiq1" ></Column>
                 <Column field="notePhysiq2" header="notePhysiq2" ></Column>
                 <Column field="notePhysiq3" header="notePhysiq3" ></Column>
                 <Column field="notePhysiq4" header="notePhysiq4" ></Column>
                 <Column field="notePhysiq5" header="notePhysiq5" ></Column>
                 <Column field="noteSVT1" header="noteSVT1" ></Column>
                 <Column field="noteSVT2" header="noteSVT2" ></Column>
                 <Column field="noteSVT3" header="noteSVT3" ></Column>
                 <Column field="noteSVT4" header="noteSVT4" ></Column>
                 <Column field="noteSVT5" header="noteSVT5" ></Column>
                 <Column field="noteEsp1" header="noteEsp1" ></Column>
                 <Column field="noteEsp2" header="noteEsp2" ></Column>
                 <Column field="noteEsp3" header="noteEsp3" ></Column>
                 <Column field="noteEsp4" header="noteEsp4" ></Column>
                 <Column field="noteEsp5" header="noteEsp5" ></Column>
                 <Column field="noteAll1" header="noteAll1" ></Column>
                 <Column field="noteAll2" header="noteAll2" ></Column>
                 <Column field="noteAll3" header="noteAll3" ></Column>
                 <Column field="noteAll4" header="noteAll4" ></Column>
                 <Column field="noteAll5" header="noteAll5" ></Column>
                 <Column field="noteArplat1" header="noteArplat1" ></Column>
                 <Column field="noteArplat2" header="noteArplat2" ></Column>
                 <Column field="noteArplat3" header="noteArplat3" ></Column>
                 <Column field="noteArplat4" header="noteArplat4" ></Column>
                 <Column field="noteArplat5" header="noteArplat5" ></Column>
                 <Column field="noteTic1" header="noteTic1" ></Column>
                 <Column field="noteTic2" header="noteTic2" ></Column>
                 <Column field="noteTic3" header="noteTic3" ></Column>
                 <Column field="noteTic4" header="noteTic4" ></Column>
                 <Column field="noteTic5" header="noteTic5" ></Column>
                 <Column field="noteConduite1" header="noteConduite1" ></Column>
                 <Column field="noteConduite2" header="noteConduite2" ></Column>
                 <Column field="noteConduite3" header="noteConduite3" ></Column>
                 <Column field="noteConduite4" header="noteConduite4" ></Column>
                 <Column field="noteConduite5" header="noteConduite5" ></Column>
                 <Column field="noteEps1" header="noteEps1" ></Column>
                 <Column field="noteEps2" header="noteEps2" ></Column>
                 <Column field="noteEps3" header="noteEps3" ></Column>
                 <Column field="noteEps4" header="noteEps4" ></Column>
                 <Column field="noteEps5" header="noteEps5" ></Column>
                 <Column field="noteHg1" header="noteHg1" ></Column>
                  <Column field="noteHg2" header="noteHg2" ></Column>
                 <Column field="noteHg3" header="noteHg3" ></Column>
                 <Column field="noteHg4" header="noteHg4" ></Column>
                 <Column field="noteHg5" header="noteHg5" ></Column>
                <Column field="noteEdhc1" header="noteEdhc1" ></Column>
                 <Column field="noteEdhc2" header="noteEdhc2" ></Column>
                 <Column field="noteEdhc3" header="noteEdhc3" ></Column>
                 <Column field="noteEdhc4" header="noteEdhc4" ></Column>
                 <Column field="noteEdhc5" header="noteEdhc5" ></Column>


     </DataTable>}
                        <Dialog visible={createProductsDialog} style={{ width: '450px' }} header="Confirm" modal footer={createProductsDialogFooter} onHide={hideCreateProductsDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                { <span>Voulez vous importer des evaluations pour ces élèves?</span>}
                            </div>
                        </Dialog>

                        <Dialog visible={updateProductsDialog} style={{ width: '450px' }} header="Confirm" modal footer={updateProductsDialogFooter} onHide={hideUpdateProductsDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                { <span>Voulez vous modifier les informations de ces élèves sélectionnés ?</span>}
                            </div>
                        </Dialog>



                        <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                            <div className="flex justify-content-center flex-column pt-6 px-3">
                                <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                                <h5>{"Importation de notes"}</h5>
                                <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                                    {retourApi}
                                </p>
                            </div>
                        </Dialog>
                    </div>
                </div>

            </div>


    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ImportNoteMatieresEleves, comparisonFn);
