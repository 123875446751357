import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import './Header.css';
import './main.css';
import Select from 'react-select';
import { Dropdown } from "primereact/dropdown";
import { SchoolService } from '../../service/SchoolService';
import { ConnexionService } from "../../service/operations/ConnexionService";
import { AnneeService } from '../../service/AnneeService';
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faBars, faUser,faShoppingCart } from '@fortawesome/free-solid-svg-icons';



import classNames from 'classnames';
import { Route, useLocation } from 'react-router-dom';
// import { CSSTransition } from 'react-transition-group';

import { AppTopbar } from '../../AppTopbar';
// import { AppFooter } from '../../AppFooter';
// import { AppMenu } from '../../AppMenu';


// import CompteParent from "./CompteParent";
// import SouscripEcole from "./SouscripEcole";


import { AppConfig } from '../../AppConfig'
import { Toast } from 'primereact/toast';
// import { useParams } from 'react-router-dom';
import { SplitButton } from 'primereact/splitbutton';
import { Tag } from 'primereact/tag';
import { Carousel } from 'primereact/carousel';
// import App2 from "../../App2";
// import App3 from "../../App3.js";
import PrimeReact from 'primereact/api';
// import { Tooltip } from 'primereact/tooltip';
// import { LoginAppTopbar } from "../../LoginAppTopbar";
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import '../../assets/demo/flags/flags.css';
import '../../assets/demo/Demos.scss';
import '../../assets/layout/layout.scss';
import '../../App.scss';
// import 'react-app-polyfill/ie11';
import { useHistory } from "react-router-dom";
// import { Image } from 'primereact/image';
// import ImprimerRapports from "../Rapports/ImprimerRapports";
// import Connexion from "./Connexion";
// import Dashboard from "../Dashboard";
import { Dialog } from "primereact/dialog";
import { useForm } from "react-hook-form";
// import { InputNumber } from "primereact/inputnumber";
import emailjs from "@emailjs/browser";
import { ProgressSpinner } from "primereact/progressspinner";



const Header = () => {
  let emptyProfil = {
    profilid: null,
    profilcode: '',
    profil_libelle: ''
};
let emptyEcole = {
    ecoleid: null,
    ecolecode: '',
    ecoleclibelle: ''
};
let connexioDtoEmpty = {
    ecoleid: null,
    profilid: null,
    email: '',
    motdePasse: '',
    login: ''
};
// let sessionVarEmpty = {
//     annee_id: null,
//     ecole_id: null,
//     connect: '',
//     profil: '',
//     nomprenom: '',
//     personnelid: null
// };

let infoPersonnEmpty = {
    personnelid: null,
    personnelnom: '',
    personnelprenom: ''
};

// let infoCandidatEmpty = {
//     candidatid: null,
//     candidat_nom: '',
//     candidat_prenom: '',
//     candidat_email: '',
//     connect: '',
//     libelleFonction:''
// };

// const [con, setConn] = useState(sessionStorage.getItem('connect'));
const toastBC = useRef(null);
const toast = useRef(null);
const [callCompte, setcallCompte] = useState(null);
const [ecole, setEcole] = useState(emptyEcole);
// const [sessionVar, setSessionVar] = useState(sessionVarEmpty);
const [infoPers, setInfoPers] = useState(infoPersonnEmpty);
// const [infosCandidat, setInfosCandidat] = useState(infoCandidatEmpty);
const [ecoles, setEcoles] = useState([]);
const [profil, setProfil] = useState(emptyProfil);
const [profils, setProfils] = useState([]);
const [idUser, setIdUser] = useState(null);
const [connexionDto, setConnexionDto] = useState(connexioDtoEmpty);
const [layoutMode, setLayoutMode] = useState('static');
const [layoutColorMode, setLayoutColorMode] = useState('light')
const [productDialog, setProductDialog] = useState(false);
// const [parentDialog, setParentDialog] = useState(false);
const [retourConnexion, setRetourConnexion] = useState('');
const [retourConnexion1, setRetourConnexion1] = useState('');
const [retourConnexionAdm, setRetourConnexionAdm] = useState('');

const [professDialog, setProfessDialog] = useState(false);
const [forgetDialog, setForgetDialog] = useState(false);
const [adminDialog, setAdminDialog] = useState(false);
const [resetDialog, setResetDialog] = useState(false);
const [submitted, setSubmitted] = useState(false);
const [submitted1, setSubmitted1] = useState(false);
const [submittedAdmin, setSubmittedAdmin] = useState(false);
const [submittedReset, setSubmittedReset] = useState(false);
const [inputStyle, setInputStyle] = useState('outlined');
const [ripple, setRipple] = useState(true);
const [staticMenuInactive, setStaticMenuInactive] = useState(false);
const [overlayMenuActive, setOverlayMenuActive] = useState(false);
const [mobileMenuActive, setMobileMenuActive] = useState(false);
const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
const [isNotSpinning, setIsNotSpinning] = useState('hidden');

const copyTooltipRef = useRef();
const location = useLocation();
const [userName, setUserName] = useState('Pouls-scolaire');
const [userEmail, setUserEmail] = useState('sousmith1@gmail.com');
const [userMessage, setUserMessage] = useState('SOUMMMMM');
const [passWord, setPassWord] = useState('');
const [monpassWord, setMonPassWord] = useState('');
const [email, setEmail] = useState('');
const [emailAdmin, setEmailAdmin] = useState('');
const [emailReset, setEmailReset] = useState('');
const [passWordAdmin, setPassWordAdmin] = useState('');
const [monEmail, setMonEmail] = useState('');
const [forgetEmail, setforgetEmail] = useState('');

const [schools, setSchools] = useState([]);


const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [currentModal, setCurrentModal] = useState(null);


// const [codeEcole, setCodeEcole] = useState('01');
const form = useRef();
let history = useHistory();
PrimeReact.ripple = true;





  // Handle changes in the dropdown selections
  const onEcoleChange = (e) => {
    setEcole(e.value);
  };

  const onProfilChange = (e) => {
    setProfil(e.value);
  };

  // Fetch data when the component mounts
  useEffect(() => {
    const schoolService = new SchoolService();
    schoolService.getSchools().then(data => setSchools(data));

    const connexSer = new ConnexionService();
    connexSer.listProfil().then(data => setProfils(data));
    connexSer.listEcoleConnecte().then(data => setEcoles(data));
  }, []);

  // Handle sticky header on scroll
  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 120);
    };
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Toggle menu visibility
  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  // Handle modal opening and closing
  const handleModalOpen = (modalName) => {
    setCurrentModal(modalName);
  };

  const handleModalClose = () => {
    setCurrentModal(null);
  };
    const hideResetDialog = () => {
        setSubmittedReset(false);
        setResetDialog(false);
    }



  // Define menu items for navigation
  const menuItems = [
    {
      label: "Accueil",
      link: "/",
      submenu: [
        { label: "Education Main", link: "index.html" },
        { label: "Online Education", link: "index-2.html" },
      ],
    },
    {
      label: "S'inscrire",
      link: "#",
      submenu: [
        { label: "Etablissement", link: "" ,
          command: () => { localStorage.setItem('conn', 'ok');
              setcallCompte(1);
              history.push("/home/professionnelle/inscrireEtablissement")

          }
        },
        { label: "Professionel", link: "",
          command: () => {
            history.push("/home/professionnelle/creerSonCompte")
        }
         },
      ],
    },
    {
      label: " Se connecter",
      link: "#",
      submenu: [
        { label: "Personnel", link: "about.html", command: () => { setProfessDialog(true); } },
        { label: "Candidat",  link: "", command: () => { setProductDialog(true); } },
        { label: "Ghi Services",  ink: "", command: () => {setAdminDialog(true);} },
        { label: "Parents", link: "",command: () => { window.location.href = 'https://pouls-scolaire.com/' } },
        { label: "Elèves", link: "", command: () => { window.location.href = 'https://pouls-scolaire.com/'} },
        { label: 'Recuperer mot de passe', link: "", command: () => { setResetDialog(true); }
      }
      ],
    },
    /* {
       label: "Emploi",
     link: "blog.html",
      submenu: [
       { label: "Offres d'emploi", link: "blog.html" },
      ],
     },*/
    { label: "Contact", link: "contact.html" },
  ];



  const ConnexionPersonnelHundle = () => {
      //console.log('SOUMAHORO');
      // console.log('Submit*****');
      (async () => {
          setSubmitted(true);
          setRetourConnexion('');
          if (monEmail.trim() && monpassWord.trim() && ecole.ecoleclibelle.trim() && profil.profil_libelle) {
              setIsNotSpinning('')
              connexionDto.email = monEmail;
              connexionDto.ecoleid = ecole.ecoleid;
              connexionDto.profilid = profil.profilid;
              connexionDto.motdePasse = monpassWord;
              connexionDto.login = monEmail;
              const connexServ = new ConnexionService();
              connexServ.seConnecter(connexionDto)
                  .then(data => {
                      console.log(data)
                      setRetourConnexion(data)
                  });
              await connexServ.infoPersonConnecteV2(monEmail, ecole.ecoleid, profil.profilid)
                  .then(data => {
                      console.log('data*** ' + data)
                      sessionStorage.setItem('connect', '2')
                      sessionStorage.setItem('CandidatId', data.personnelid)
                      sessionStorage.setItem('infosCandidat', JSON.stringify(data))
                      sessionStorage.setItem('NomPrenom', data.personnelnom + ' ' + data.personnelprenom)
                      sessionStorage.setItem('profil', data.profil)
                      console.log('profil connexion ::: ' + data.profil)
                      sessionStorage.setItem('EcoleID', ecole.ecoleid)
                      sessionStorage.setItem('EcoleLibelle', ecole.ecoleclibelle)
                      setInfoPers(data)
                  });
              //get IDUser
              await connexServ.idUserConnecte(monEmail)
                  .then(data => {
                      console.log('idUser' + data)
                      setIdUser(data)
                      sessionStorage.setItem('idUser', data)
                  });

              await connexServ.getMainyearByEcole(ecole.ecoleid)
                  .then(data => {
                      sessionStorage.setItem('AnneEncours', data.id)
                      sessionStorage.setItem('niveauEnseignementId', data.niveauEnseignement?.id)
                      sessionStorage.setItem('periodiciteId', data.periodicite?.id)
                  });

              const anneeService = new AnneeService();
              await anneeService.getInfosAnnee(ecole.ecoleid).then(res => {
                  sessionStorage.setItem('topBarInfos', JSON.stringify(res));
                  console.log(res);
              }
              )
              window.location.reload(true);

          } else {
              toast.current.show({ severity: 'error', summary: 'pouls-scolaire: Messages d"erreur', detail: 'Veuillez renseigner les différents champs svp', life: 3000 });

          }
          setIsNotSpinning('hidden')
      })()
  }



  const renderModalContent = () => {
    switch (currentModal) {
      case 'Personnel':
        return (
          <form>
            {/* Formulaire pour Personnel */}
            <div  id='HomePage' class="account-area pt-120 pb-120">
                <div class="row justify-content-center">
                  <div class="account-mainn p-5">
                      <form action="#" class="account-form">
                          <div class="account-form-item mb-20">
                              <div class="account-form-label">
                                  <label>Login</label>
                              </div>
                              <div class="account-form-input">
                                  <InputText inputid="email1" type="text" value={monEmail == null ? '' : monEmail} onChange={(e) => setMonEmail(e.target.value)} placeholder="Login" className="w-full md:w-30rem mb-5" style={{ padding: '1rem' }} />
                              </div>
                          </div>
                          <div class="account-form-item mb-15">
                              <div class="account-form-label">
                                  <label>Mot de passe</label>
                                  <a href="#">Forgot Password ?</a>
                              </div>
                              <div class="account-form-input account-form-input-pass">
                                  <Password inputid="passWord" value={monpassWord == null ? '' : monpassWord} onChange={(e) => setMonPassWord(e.target.value)} placeholder="Password" toggleMask className="w-full mb-5" inputClassName='w-full p-3 md:w-30rem' feedback={false}></Password>
                              </div>
                          </div>
                          <div class="account-form-item mb-20">
                              <div class="account-form-label">
                                  <label>Ecole</label>
                              </div>
                              {/* <Select options={ecole} onChange={onEcoleChange} /> */}
                              <Dropdown id="codeEcole" options={ecoles} value={ecole == null ? '' : ecole} onChange={onEcoleChange} optionLabel="ecoleclibelle" filter showClear filterBy="ecoleclibelle"
                              ></Dropdown>

                          </div>
                          <div class="account-form-item mb-20">
                              <div class="account-form-label">
                                  <label>Profil</label>
                              </div>
                              {/* <Select options={profil} /> */}
                              <Dropdown id="profil" options={profils} value={profil == null ? '' : profil} onChange={onProfilChange} optionLabel="profil_libelle" filter showClear filterBy="profil_libelle"
                              ></Dropdown>

                          </div>

                          <div class="account-form-button b-0">
                              <button type="submit" class="account-btn" onClick={ConnexionPersonnelHundle} >Connexion</button>
                          </div>
                      </form>
                  </div>
                </div>
            </div>
          </form>
        );
      case 'Candidat':
        return (
          <form>
            {/* Formulaire pour Candidat */}
            <div  id='HomePage' class="account-area pt-120 pb-120">
                <div class="row justify-content-center">
                  <div class="account-mainn p-5">
                      <form action="#" class="account-form">
                          <div class="account-form-item mb-20">
                              <div class="account-form-label">
                                  <label>Login</label>
                              </div>
                              <div class="account-form-input">
                                  <input type="email" placeholder="Enter Your Email"/>
                              </div>
                          </div>
                          <div class="account-form-item mb-15">
                              <div class="account-form-label">
                                  <label>Mot de passe</label>
                                  <a href="#">Forgot Password ?</a>
                              </div>
                              <div class="account-form-input account-form-input-pass">
                                  <input type="text" placeholder="*********"/>
                                  <span><i class="fa-thin fa-eye"></i></span>
                              </div>
                          </div>
                          <div class="account-form-item mb-20">
                              <div class="account-form-label">
                                  <label>Ecole</label>
                              </div>
                              <Select options={ecole} />

                          </div>
                          <div class="account-form-item mb-20">
                              <div class="account-form-label">
                                  <label>Profil</label>
                              </div>
                              <Select options={profil} />

                          </div>
                          <div class="account-form-button b-0">
                              <button type="submit" class="account-btn">Connexion</button>
                          </div>
                      </form>
                  </div>
                </div>
            </div>
          </form>
        );
      case 'GhiServices':
        return (
          <form>
            {/* Formulaire pour Ghi Services */}

          </form>
        );
      case 'Parents':
        return (
          <form>
            {/* Formulaire pour Parents */}

          </form>
        );
      case 'Eleves':
        return (
          <form>
            {/* Formulaire pour Elèves */}

          </form>
        );
      default:
        return null;
    }
  };


    const recuperMotDepasse = () => {
        let destinataire = emailReset;
        let message;
        let login;
        let motDepasse;

        let objet = "Vos paramètres d'identifications"

        const connexServ = new ConnexionService();
        connexServ.infosParam(emailReset)
            .then(data => {

                if (data.message === 'utilisateur trouve') {

                    motDepasse = data.parametre.motDepass
                    login = data.parametre.login
                    message = "Login :" + login + "  Mot de passe:" + motDepasse;
                    connexServ.sendEmail(destinataire, message, objet)
                        .then(data => {
                            toast.current.show({ severity: 'success', summary: 'Pouls-scolaire: Succes', detail: 'mail envoyé!', life: 3000 });

                        });
                } else {
                    toast.current.show({ severity: 'error', summary: 'Pouls-scolaire: Messages d"erreur', detail: 'Cette adresse email n"existe pas', life: 3000 });

                }
                //  console.log(data)
                sessionStorage.setItem('isLoggedIn', true)
                setInfoPers(data)

            });




    }



// DEBUT  MENU DE NAVIGATION


// const [isMenuOpen, setIsMenuOpen] = useState(false);
//   const [isSticky, setIsSticky] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openSubMenuIndex, setOpenSubMenuIndex] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 120);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // const handleMenuToggle = () => {
  //   setIsMenuOpen(!isMenuOpen);
  // };

  // const handleCloseMenu = () => {
  //   setIsMenuOpen(false);
  // };

  // const handleModalOpen = () => {
  //   setIsModalOpen(true);
  // };

  // const handleModalClose = () => {
  //   setIsModalOpen(false);
  // };

  const toggleSubMenu = (index) => {
    setOpenSubMenuIndex((prevIndex) => (prevIndex === index ? null : index));
  };




    // FIN MENU DE NAVIGATION
    // Cette ligne indique la fin d'une section du code qui gère le menu de navigation.




    // +++++++++++++++++++++++++++++ MODAL COMPTE PARENT +++++++++++++++++++++++++++++
    // Ici, nous allons gérer un dialogue modal pour le compte parent.

    const [parentDialog, setParentDialog] = useState(false); // Déclare un état pour contrôler l'affichage du dialogue parent.
    // `parentDialog` est une variable qui indique si le dialogue est ouvert (true) ou fermé (false).
    // `setParentDialog` est une fonction qui permet de changer cet état.

    const saveParent = () => { // Fonction qui sera appelée pour enregistrer les informations du parent.
        const connexServ = new ConnexionService(); // Crée une instance d'un service de connexion.

        if (ecole) { // Vérifie si la variable `ecole` est définie.
            // Si oui, nous enregistrons l'ID de l'école dans le stockage de session.
            sessionStorage.setItem('EcoleID', ecole.ecoleid);

            // Nous appelons une méthode pour obtenir l'année scolaire principale par rapport à l'école.
            connexServ.getMainyearByEcole(ecole.ecoleid)
                .then(data => { // Lorsque nous recevons les données, nous les traitons dans cette fonction.
                    // Nous enregistrons plusieurs informations dans le stockage de session.
                    sessionStorage.setItem('AnneEncours', data.id);
                    sessionStorage.setItem('niveauEnseignementId', data.niveauEnseignement.id);
                    sessionStorage.setItem('periodiciteId', data.periodicite.id);
                    sessionStorage.setItem('CompteParent', '1'); // Indique que le compte parent est actif.

                    // Redirige l'utilisateur vers la page du compte parent après l'enregistrement.
                    history.push("/home/compteParent");
                });

        } else { // Si la variable `ecole` n'est pas définie, nous montrons un message d'erreur.
            toast.current.show({
                severity: 'error', // Type de notification (ici, une erreur).
                summary: 'pouls-scolaire: Messages d"erreur', // Résumé du message.
                detail: 'Veuillez renseigner l"ecole svp', // Détails du message d'erreur.
                life: 3000 // Durée d'affichage de la notification en millisecondes.
            });
        }
    }
    const productResetDialogFooter = (
        <>
            <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideResetDialog}/>
            <div className="header-btn d-bock d-sm-block">
                <a className="header-btn theme-btn theme-btn-medium" onClick={recuperMotDepasse}>Envoyer le mail</a>
            </div>
        </>
    )
    const callCompteParent = () => { // Fonction qui ouvre le dialogue pour le compte parent.
        setParentDialog(true); // Met l'état `parentDialog` à true, ce qui ouvre le dialogue.
    };

    const hideParentDialog = () => { // Fonction qui ferme le dialogue.
        // setSubmitted1(false); // (Commenté) Peut-être utilisé pour réinitialiser un état.
        setParentDialog(false); // Met l'état `parentDialog` à false, ce qui ferme le dialogue.
    }

    // Le pied de page du dialogue avec deux boutons.
    const parentDialogFooter = ( // Composant JSX pour le pied de page du dialogue.
        <>
            <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideParentDialog} />
            {/* Bouton pour annuler, qui ferme le dialogue. */}
            {/* <Button label="Se connecter" icon="pi pi-check" className="p-button-text" onClick={saveParent} />  */}
            <div class="header-btn d-bock d-sm-block">
              <a class="header-btn theme-btn theme-btn-medium" onClick={saveParent} >Se connecter</a>
            </div>
            {/* Bouton pour se connecter, qui appelle la fonction `saveParent` pour enregistrer le parent. */}
        </>
    );


    // +++++++++++++++++++++++++++++ MODAL COMPTE PROFESSEUR  +++++++++++++++++++++++++++++

    // Fonction qui gère le processus de connexion
    const connectProcess = () => {
      // On lance une fonction asynchrone pour gérer les opérations asynchrones
      (async () => {
          // Indiquer que le formulaire a été soumis
          setSubmitted(true);
          // Réinitialiser le message de retour de connexion
          setRetourConnexion('');

          // Vérifier que tous les champs nécessaires sont remplis
          if (monEmail.trim() && monpassWord.trim() && ecole.ecoleclibelle.trim() && profil.profil_libelle) {
              // Indiquer qu'un chargement est en cours
              setIsNotSpinning('')

              // Préparer l'objet de connexion avec les données saisies
              connexionDto.email = monEmail;
              connexionDto.ecoleid = ecole.ecoleid;
              connexionDto.profilid = profil.profilid;
              connexionDto.motdePasse = monpassWord;
              connexionDto.login = monEmail;

              // Créer une instance du service de connexion
              const connexServ = new ConnexionService();

              // Appel au service de connexion
              connexServ.seConnecter(connexionDto)
                  .then(data => {
                      // Afficher les données reçues de la connexion dans la console
                      console.log(data)
                      // Mettre à jour le message de retour de connexion avec les données
                      setRetourConnexion(data)
                  });

              // Récupérer les informations de la personne connectée
              await connexServ.infoPersonConnecteV2(monEmail, ecole.ecoleid, profil.profilid)
                  .then(data => {
                      // Afficher les données reçues dans la console
                      console.log('data*** ' + data)

                      // Stocker des informations dans le stockage de session
                      sessionStorage.setItem('connect', '2') // Indiquer que l'utilisateur est connecté
                      sessionStorage.setItem('CandidatId', data.personnelid) // ID de la personne
                      sessionStorage.setItem('infosCandidat', JSON.stringify(data)) // Informations détaillées
                      sessionStorage.setItem('NomPrenom', data.personnelnom + ' ' + data.personnelprenom) // Nom complet
                      sessionStorage.setItem('profil', data.profil) // Profil de l'utilisateur
                      console.log('profil connexion ::: ' + data.profil) // Afficher le profil dans la console
                      sessionStorage.setItem('EcoleID', ecole.ecoleid) // ID de l'école
                      sessionStorage.setItem('EcoleLibelle', ecole.ecoleclibelle) // Nom de l'école

                      // Mettre à jour l'état avec les informations personnelles
                      setInfoPers(data)
                  });

              // Récupérer l'ID de l'utilisateur connecté
              await connexServ.idUserConnecte(monEmail)
                  .then(data => {
                      console.log('idUser' + data) // Afficher l'ID de l'utilisateur dans la console
                      setIdUser(data) // Mettre à jour l'état avec l'ID de l'utilisateur
                      sessionStorage.setItem('idUser', data) // Stocker l'ID dans le stockage de session
                  });

              // Récupérer l'année scolaire principale par rapport à l'école
              await connexServ.getMainyearByEcole(ecole.ecoleid)
                  .then(data => {
                      // Stocker les informations de l'année dans le stockage de session
                      sessionStorage.setItem('AnneEncours', data.id) // ID de l'année en cours
                      sessionStorage.setItem('niveauEnseignementId', data.niveauEnseignement?.id) // Niveau d'enseignement
                      sessionStorage.setItem('periodiciteId', data.periodicite?.id) // ID de la périodicité
                  });

              // Créer une instance du service d'année
              const anneeService = new AnneeService();
              // Récupérer les informations de l'année scolaire
              await anneeService.getInfosAnnee(ecole.ecoleid).then(res => {
                  sessionStorage.setItem('topBarInfos', JSON.stringify(res)); // Stocker les infos dans le stockage de session
                  console.log(res); // Afficher les infos de l'année dans la console
              });

              // Recharger la page pour appliquer les changements
              window.location.reload(true);

          } else {
              // Afficher un message d'erreur si des champs sont vides
              toast.current.show({
                  severity: 'error',
                  summary: 'pouls-scolaire: Messages d\'erreur',
                  detail: 'Veuillez renseigner les différents champs svp',
                  life: 3000
              });
          }

          // Cacher l'indicateur de chargement
          setIsNotSpinning('hidden')
      })() // Appeler la fonction asynchrone
    }
    const hideforgetDialog = () => {
        setForgetDialog(false);
    }

    // Fonction pour cacher la boîte de dialogue du professeur
    const hideProffDialog = () => {
      // Réinitialiser l'état de soumission
      setSubmitted(false);
      // Fermer la boîte de dialogue du professeur
      setProfessDialog(false);
    }

    // Définition du pied de page de la boîte de dialogue du professeur
    const professDialogFooter = (
      <>
          {/* Commenté : Lien pour mot de passe oublié */}
          {/* <Button label="Mot de passe oublié?" icon="pi pi-check" className="p-button-text" onClick={showForgetForm} /> */}

          {/* Champ d'indicateur de chargement */}
          <div className="field">
              <ProgressSpinner className={isNotSpinning} style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
          </div>

          {/* Bouton pour annuler */}
          <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideProffDialog} />

          {/* Bouton pour se connecter */}
          <div class="header-btn d-bock d-sm-block">
              <a class="header-btn theme-btn theme-btn-medium" onClick={connectProcess} >Se connecter</a>
          </div>
          {/* <Button label="Se connecter" icon="pi pi-check" className="p-button-text" onClick={connectProcess} /> */}
      </>
    )



    // CANDIDAT

    const hideDialog = () => {
          setSubmitted1(false);
          setProductDialog(false);
      }
    const sendEmail = (e) => {
        //  e.preventDefault();
        emailjs.sendForm('service_94gv03t', 'template_khmrq5i', form.current, 'rQ8B8i3jHyGwChdlj')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };
    const emailService = (e) => {
        //  e.preventDefault();
        if (forgetEmail.trim()) {
            setUserName('POULS-SCOLAIRE');
            setUserEmail(forgetEmail.trim());
            setUserMessage('Bonjour Monsieur Soumahoro Moustapha' +
                'Veuillez utiliser ces paramètres pour vous connecter à notre application' + '\n');
            sendEmail();
        }
    };
      //   save info candidat
      const saveProduct1 = () => {
        const connexServ = new ConnexionService();
        console.log('Submit');
        setSubmitted1(true);
        setRetourConnexion1('');
        if (email.trim() && passWord.trim()) {
            connexServ.connexionCandidat(email, passWord)
                .then(data => {
                    console.log(data)
                    setRetourConnexion1(data)
                });
            connexServ.infoPersonConnecteCandidat(email)
                .then(data => {
                    //  console.log(data)
                    sessionStorage.setItem('isLoggedIn', true)
                    setInfoPers(data)
                    sessionStorage.setItem('connectcandidat','ok')
                    sessionStorage.setItem('connect', data.connect)
                    sessionStorage.setItem('CandidatId', data.candidatid)
                    sessionStorage.setItem('infosCandidat', JSON.stringify(data));
                    sessionStorage.setItem('NomPrenom', data.candidat_nom + ' ' + data.candidat_prenom);
                    sessionStorage.setItem('emailCandidat', email);
                    sessionStorage.setItem('profil', 'CANDIDAT')
                    sessionStorage.setItem('refresh', 1)
                    sessionStorage.setItem('fonction', data.libelleFonction)


                });
            //get IDUser
            connexServ.idUserConnecte(email)
                .then(data => {
                    console.log(data)
                    setIdUser(data)
                    sessionStorage.setItem('idUser', data)
                });



        } else {
            toast.current.show({ severity: 'error', summary: 'pouls-scolaire: Messages d"erreur', detail: 'Veuillez renseigner les différents champs svp', life: 3000 });

        }
    }

    const productDialogFooter = (
      <>
          <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
          {/* <Button label="Se connecter" icon="pi pi-check" className="p-button-text" onClick={saveProduct1} /> */}
          <div class="header-btn d-bock d-sm-block">
            <a class="header-btn theme-btn theme-btn-medium" onClick={saveProduct1} >Se connecter</a>
          </div>
      </>
    )



    //  ADMINISTRATEUR




  // ADMINISTRATEUR

// Fonction pour sauvegarder les informations de l'administrateur
const saveAdmin = () => {
  // Indique que le formulaire a été soumis
  setSubmittedAdmin(true);

  // Réinitialise le message de retour de connexion
  setRetourConnexionAdm('');

  // Vérifie si l'email et le mot de passe ne sont pas vides
  if (emailAdmin.trim() && passWordAdmin.trim()) {
      // Remplit l'objet connexionDto avec les informations de l'administrateur
      connexionDto.email = emailAdmin;         // Email de l'administrateur
      connexionDto.motdePasse = passWordAdmin; // Mot de passe de l'administrateur
      connexionDto.login = emailAdmin;          // Login basé sur l'email

      // Crée une instance du service de connexion
      const connexServ = new ConnexionService();

      // Appelle la méthode de connexion pour l'administrateur
      connexServ.seConnecterAdmin(connexionDto)
          .then(data => {
              console.log("data Admin " + data); // Affiche les données de l'administrateur dans la console
              sessionStorage.setItem('profil', data); // Stocke le profil de l'administrateur dans le stockage de session
              setRetourConnexionAdm(data); // Met à jour l'état avec les données de retour
          });

      // Indique que l'administrateur est connecté
      sessionStorage.setItem('connect', '2');

      // Appelle la méthode pour obtenir l'ID de l'utilisateur connecté
      connexServ.idUserConnecte(emailAdmin)
          .then(data => {
              console.log(data); // Affiche l'ID de l'utilisateur dans la console
              setIdUser(data); // Met à jour l'état avec l'ID de l'utilisateur
              sessionStorage.setItem('idUser', data); // Stocke l'ID de l'utilisateur dans le stockage de session
          });

  } else {
      // Affiche un message d'erreur si les champs ne sont pas remplis
      toast.current.show({
          severity: 'error',
          summary: 'pouls-scolaire: Messages d\'erreur',
          detail: 'Veuillez renseigner les différents champs svp',
          life: 3000
      });
  }
}
    const forgetDialogFooter = (
        <>
            <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideforgetDialog} />
            <Button label="Valider" icon="pi pi-check" className="p-button-text" onClick={emailService} />
        </>
    )

// Footer du dialogue d'administration avec un bouton de connexion
const productAdminDialogFooter = (
  <>
      {/*
      <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideAdminDialog} />
      */}
      {/* Bouton pour se connecter */}
      {/* <Button label="Se connecter" icon="pi pi-check" className="p-button-text" /> */}
      <div class="header-btn d-bock d-sm-block">
        <a class="header-btn theme-btn theme-btn-medium" onClick={saveAdmin} >Se connecter</a>
      </div>
  </>
)

// Fonction pour masquer le dialogue d'administration
const hideAdminDialog = () => {
  // Réinitialise le statut de soumission
  setSubmittedAdmin(false);

  // Ferme le dialogue d'administration
  setAdminDialog(false);
}






  return (
    <div>
      <header id='Header'>
        {/* Contenu de l'en-tête */}
        <div className={`header-area header-sticky ${isSticky ? 'sticky' : ''}`}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-7 col-lg-6 col-md-6 col-6">
                <div className="header-left">
                  <div className="header-logo">
                    <NavLink exact to="/">
                      <img src="assets/img/logo/logo-app.png" width={50} alt="" />
                    </NavLink>
                  </div>
                  <nav className="main-menu mobile-menu d-none d-xl-block" id="mobile-menu">
                    <ul>
                      {menuItems.map((menuItem, index) => (
                        <li className="menu-has-child" key={index}>
                          <a href={menuItem.link}>{menuItem.label}</a>
                          {menuItem.submenu && (
                            <ul className="submenu">
                              {menuItem.submenu.map((submenuItem, subIndex) => (
                                <li key={subIndex}>
                                  <a
                                    href={submenuItem.link}
                                    onClick={(e) => {
                                      if (submenuItem.command) {
                                        e.preventDefault(); // Empêche la navigation si une commande est définie
                                        submenuItem.command(); // Appelle la commande
                                      }
                                    }}
                                  >
                                    {submenuItem.label}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              </div>

              <div className="col-xl-5 col-lg-6 col-md-6 col-6">
                <div className="header-right">
                  <div className="header-btn d-none d-sm-block">
                  {/* <Button label="COMPTE PARENT ICI" severity="secondary" text raised onClick={() => callCompteParent()} className='btn btn-primary' /> */}

                    <a href="#" className="header-btn theme-btn theme-btn-medium" onClick={() => callCompteParent()}>Compte parent</a>
                  </div>
                  <div className="header-menu-bar d-xl-none ml-10">
                    <span className="header-menu-bar-icon side-toggle" onClick={handleMenuToggle}>
                    <FontAwesomeIcon className="fa-light fa-bars" icon={faBars} />
                      {/* <i className="fa-light fa-bars"></i> */}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className={`side-info ${isMenuOpen ? 'info-open' : ''}`}>
        <button className="side-info-close" onClick={handleCloseMenu}>X</button>

          <div className="sidebar-logo-wrapper mb-25">
            <div className="row align-items-center">
              <div className="col-xl-6 col-8">
                <div className="sidebar-logo">
                  {/* <a href="index.html">
                    <img src="assets/img/logo/logo-white.png" alt="logo-img" />
                  </a> */}
                  {/* <a class="layout-topbar-logo" href="#/"><img src="assets/layout/images/logo-gain.png" alt="logo"/><span>Pouls-scolaire</span></a> */}
                </div>
              </div>
              <div className="col-xl-6 col-4">
                <div className="sidebar-close-wrapper text-end">
                  <button className="sidebar-close side-info-close">
                    <i className="fal fa-times" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <nav id="Mon-menu-mobile" className="mean-nav">
            <ul>
              {menuItems.map((menuItem, index) => (
                <li key={index} className="menu-has-child">
                  <a href={menuItem.link} onClick={() => toggleSubMenu(index)}>
                    {menuItem.label}
                    <span style={{ marginLeft: '8px', position:'absolute', right:"0" }}>
                      {openSubMenuIndex === index ? '-' : '+'}
                    </span>
                  </a>
                  {menuItem.submenu && openSubMenuIndex === index && (
                    <ul className="dropdown-submenu">
                      {menuItem.submenu.map((submenuItem, subIndex) => (
                        <li key={subIndex}>
                          <a
                            href={submenuItem.link}
                            onClick={(e) => {
                              if (submenuItem.command) {
                                e.preventDefault();
                                submenuItem.command();
                              }
                            }}
                          >
                            {submenuItem.label}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
              <li> <div className="header-btn d-block d-sm-block">
                  {/* <Button label="COMPTE PARENT ICI" severity="secondary" text raised onClick={() => callCompteParent()} className='btn btn-primary' /> */}

                    <a href="#" className="header-btn theme-btn theme-btn-medium" onClick={() => callCompteParent()}>Compre parent</a>
                  </div>
                </li>
            </ul>
          </nav>
      </div>
      {isMenuOpen && <div className="offcanvas-overlay overlay-open" onClick={handleCloseMenu}></div>}

      {/**
       ++++++++++++++++++++++++++++ COMPTE PARENT ++++++++++++++++++++++++++++
        Ce code permet d'afficher un modal pour l'ajout d'un compte parent
      **/}

      <Dialog visible={parentDialog} style={{ width: '450px' }} header="" modal className="p-fluid" footer={parentDialogFooter} onHide={hideParentDialog}>
        <div className="flex flex-column align-items-center justify-content-center">
            <div style={{ borderRadius: '56px', padding: '0.3rem', background: 'linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)' }}>
                <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: '53px' }}>
                    <div className="text-center mb-5">
                        <div className="text-900 text-3xl font-medium mb-3">Compte Parent! </div>
                        <span className="text-600 font-medium">Parent Responsable</span>
                    </div>
                    <div>
                        <label htmlFor="codeEcole" className="block text-900 text-xl font-medium mb-2"> Ecole </label>
                        <Dropdown id="codeEcole" options={ecoles} value={ecole == null ? '' : ecole} onChange={onEcoleChange} optionLabel="ecoleclibelle" filter showClear filterBy="ecoleclibelle"></Dropdown>
                    </div>

                </div>
            </div>
        </div>
      </Dialog>



      {/*  */}


      <Dialog visible={professDialog} style={{ width: '650px' }} header="" modal className="p-fluid" footer={professDialogFooter} onHide={hideProffDialog}>
          <div className="flex flex-column align-items-center justify-content-center">
              <div style={{ borderRadius: '56px', padding: '0.3rem', background: 'linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)' }}>
                  <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: '53px' }}>

                      <div>
                          <label htmlFor="email1" className="block text-900 text-xl font-medium mb-2">
                              Login
                          </label>
                          <InputText inputid="email1" type="text" value={monEmail == null ? '' : monEmail} onChange={(e) => setMonEmail(e.target.value)} placeholder="Login" className="w-full md:w-30rem mb-5" style={{ padding: '1rem' }} />

                          <label htmlFor="passWord" className="block text-900 text-xl font-medium mb-2">
                              Mot de passe
                          </label>
                          <Password inputid="passWord" value={monpassWord == null ? '' : monpassWord} onChange={(e) => setMonPassWord(e.target.value)} placeholder="Password" toggleMask className="w-full mb-5" inputClassName='w-full p-3 md:w-30rem' feedback={false}></Password>

                          <label htmlFor="codeEcole" className="block text-900 text-xl font-medium mb-2">
                              Ecole
                          </label>
                          <Dropdown id="codeEcole" options={ecoles} value={ecole == null ? '' : ecole} onChange={onEcoleChange} optionLabel="ecoleclibelle" filter showClear filterBy="ecoleclibelle"
                          ></Dropdown>

                          <label htmlFor="profil" className="block text-900 text-xl font-medium mb-2">
                              Profil
                          </label>
                          <Dropdown id="profil" options={profils} value={profil == null ? '' : profil} onChange={onProfilChange} optionLabel="profil_libelle" filter showClear filterBy="profil_libelle"
                          ></Dropdown>

                      </div>

                  </div>
              </div>
          </div>

      </Dialog>



      {/* Dialog candidat */}
      <Dialog visible={productDialog} style={{ width: '650px' }} header="" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
          <div className="flex flex-column align-items-center justify-content-center">
              <div style={{ borderRadius: '56px', padding: '0.3rem', background: 'linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)' }}>
                  <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: '53px' }}>
                      <div className="text-center mb-5">
                          <div className="text-900 text-3xl font-medium mb-3">Mon espace Candidat! </div>
                          <span className="text-600 font-medium">Connectez vous pour continuer</span>
                      </div>
                      <div>
                          <label htmlFor="email1" className="block text-900 text-xl font-medium mb-2">
                              Login
                          </label>
                          <InputText inputid="email1" type="text" value={email == null ? '' : email} onChange={(e) => setEmail(e.target.value)} placeholder="Login" className="w-full md:w-30rem mb-5" style={{ padding: '1rem' }} />
                          <label htmlFor="passWord" className="block text-900 text-xl font-medium mb-2">
                              Mot de passe
                          </label>
                          <Password inputid="passWord" feedback={false} value={passWord == null ? '' : passWord} onChange={(e) => setPassWord(e.target.value)} placeholder="Password" toggleMask className="w-full mb-5" inputClassName='w-full p-3 md:w-30rem'></Password>

                      </div>

                  </div>
              </div>
          </div>

      </Dialog>

      {/* Dialog Admin */}
      <Dialog visible={adminDialog} style={{ width: '650px' }} header="" modal className="p-fluid" footer={productAdminDialogFooter} onHide={hideAdminDialog}>
          <div className="flex flex-column align-items-center justify-content-center">
              <div style={{ borderRadius: '56px', padding: '0.3rem', background: 'linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)' }}>
                  <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: '53px' }}>
                      <div className="text-center mb-5">
                          <div className="text-900 text-3xl font-medium mb-3">Administrateur Gain </div>
                          <span className="text-600 font-medium">Connectez vous pour continuer</span>
                      </div>
                      <div>
                          <label htmlFor="email1" className="block text-900 text-xl font-medium mb-2">
                              Login
                          </label>
                          <InputText inputid="email1" type="text" value={emailAdmin == null ? '' : emailAdmin} onChange={(e) => setEmailAdmin(e.target.value)} placeholder="Login" className="w-full md:w-30rem mb-5" style={{ padding: '1rem' }} />
                          <label htmlFor="passWord" className="block text-900 text-xl font-medium mb-2">
                              Mot de passe
                          </label>
                          <Password inputid="passWord" feedback={false} value={passWordAdmin == null ? '' : passWordAdmin} onChange={(e) => setPassWordAdmin(e.target.value)} placeholder="Password" toggleMask className="w-full mb-5" inputClassName='w-full p-3 md:w-30rem'></Password>

                      </div>

                  </div>
              </div>
          </div>
      </Dialog>
        <Dialog visible={resetDialog} style={{ width: '650px' }} header="" modal className="p-fluid" footer={productResetDialogFooter} onHide={hideResetDialog}>
            <div className="flex flex-column align-items-center justify-content-center">
                <div style={{ borderRadius: '56px', padding: '0.3rem', background: 'linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)' }}>
                    <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: '53px' }}>
                        <div className="text-center mb-5">
                            <div className="text-900 text-3xl font-medium mb-3">Utilisateur </div>
                            <span className="text-600 font-medium">Recuper son mot de passe</span>
                        </div>
                        <div>
                            <label htmlFor="email1" className="block text-900 text-xl font-medium mb-2">
                                Email
                            </label>
                            <InputText inputid="email1" type="text" value={emailReset == null ? '' : emailReset} onChange={(e) => setEmailReset(e.target.value)} placeholder="Adresse email" className="w-full md:w-30rem mb-5" style={{ padding: '1rem' }} />

                        </div>

                    </div>
                </div>
            </div>
        </Dialog>
        <Dialog visible={forgetDialog} style={{ width: '650px' }} header="" modal className="p-fluid" footer={forgetDialogFooter} onHide={hideforgetDialog}>
            <form ref={form} onSubmit={emailService}>
                <div className="flex flex-column align-items-center justify-content-center">
                    <div style={{ borderRadius: '56px', padding: '0.3rem', background: 'linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)' }}>
                        <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: '53px' }}>

                            <div>
                                <label htmlFor="email1" className="block text-900 text-xl font-medium mb-2">
                                    Login
                                </label>
                                <InputText inputid="email1" type="text" value={forgetEmail == null ? '' : forgetEmail} onChange={(e) => setforgetEmail(e.target.value)} placeholder="Adresse email" className="w-full md:w-30rem mb-5" style={{ padding: '1rem' }} />

                            </div>
                            <input type="text" name="user_name" value={userName} onChange={(e) => setUserName(e.target.value)} hidden={true} />

                            <input type="email" name="user_email" value={userEmail} onChange={(e) => setUserEmail(e.target.value)} hidden={true} />

                            <textarea name="message" value={userMessage} onChange={(e) => setUserMessage(e.target.value)} hidden={true} />
                            <input type="submit" value="Send" hidden={true} />
                        </div>
                    </div>
                </div>
            </form>
        </Dialog>



    </div>
  );
};

export default Header;
